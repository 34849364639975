import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @mui
import { Typography, Stack, Divider } from '@mui/material';
export default function CustomDivider({ title, sx, dividerColor, textStyle, ...other }) {
    return (_jsxs(Stack, { direction: "row", alignContent: "center", gap: 2, sx: sx, ...other, children: [_jsx(Typography, { variant: "h5", color: "text.secondary", sx: textStyle, children: title }), _jsx(Divider, { sx: {
                    my: 2,
                    flex: '1',
                    background: dividerColor,
                }, flexItem: true, variant: "fullWidth" })] }));
}
